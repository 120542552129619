<div class="container">
    <div class="portada">
        <div class="bg">
        </div>
        <div class="logo">
            <img src="assets/logo_blanco_transparente.png" alt="">
        </div>
    </div>
    <div class="body">
       
        <div class="box1">
            Tenemos una opción de alojamiento para cada necesidad.

        </div>
        <div class="box2" #formulario>
            <b>Busca tu Alojamiento ideal</b> dentro del parque Termal de Concordia o en la costa
            del Río Uruguay. Completa el siguiente formulario y obten tarifa y disponibilidad al instante.
        </div>

        <div class="form">
            <div class="mini-cont">
                <div class="logo-number">
                    <p>1</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <mat-form-field appearance="outline" (click)="picker.open()">
                    <mat-label>Selecciona un Ingreso y una Salida (10h)</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [min]="min1">
                        <input matStartDate placeholder="Ingreso" (dateChange)="inputChange()" [(ngModel)]="dateIn"
                            #checkinTarget>
                        <input matEndDate placeholder="Salida" (dateChange)="inputChange()" [(ngModel)]="dateOut">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <div class="mini-cont">
                <div class="logo-number">
                    <p>2</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Número de adultos (7 o más años)</mat-label>
                    <mat-select [(ngModel)]="busqueda.mayores" (selectionChange)="ajustarTarifa()">
                        <mat-option *ngFor="let pax of [1,2,3,4,5,6,7,8]" [value]="pax">{{pax}}
                            {{pax>1?'adultos':'adulto'}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>accessibility_new</mat-icon>
                </mat-form-field>
            </div>

            <div class="mini-cont">
                <div class="logo-number">
                    <p>3</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Número de menores (de 3 a 6 años)</mat-label>
                    <mat-select [(ngModel)]="busqueda.menores" (selectionChange)="ajustarTarifa()">
                        <mat-option *ngFor="let pax of [0,1,2,3,4,5,6,7]" [value]="pax"> 
                            {{pax>0?pax:''}} {{pax==0?'sin menores':pax>1?'menores':'menor'}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>child_care</mat-icon>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="mini-cont">
                <div class="logo-number">
                    <p>4</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Número de mascotas (razas pequeñas hasta 9 Kg)</mat-label>
                    <mat-select [(ngModel)]="busqueda.mascotas" (selectionChange)="ajustarTarifa()">
                        <mat-option *ngFor="let pax of [0,1,2]" [value]="pax">{{pax>0?pax:''}} {{pax==0?'sin mascotas':pax>1?'mascotas (solo en Termas)':'mascota'}} </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>pets</mat-icon>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>

            <div class="mini-cont">
                <div class="logo-number">
                    <p>5</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>¿Salida Tardía?</mat-label>
                    <mat-select [(ngModel)]="busqueda.late" (selectionChange)="inputChange()"
                        [compareWith]="compareCheckout">
                        <mat-option value="no">
                            Salgo a las 10 h.
                        </mat-option>
                        <mat-option value="si">
                            Extiendo hasta las 18 h (con Cargo)
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>schedule</mat-icon>
                </mat-form-field>
            </div>

            <div class="mini-cont">
                <div class="logo-number">
                    <p>6</p>
                    <img src="assets/icons/logo96.png" alt="">
                </div>
                <div class="center">
                    <button mat-raised-button color="primary" (click)="calcularTarifa()" [disabled]="searching">
                        <div class="center2">
                            <ng-container *ngIf="!searching;else spinn">
                                <mat-icon>search</mat-icon>
                                <span>Buscar Tarifas y Disponibilidad</span>
                            </ng-container>
                            <ng-template #spinn>
                                <mat-spinner diameter=20></mat-spinner>
                                <span>Buscando... Al finalizar verás las tarifas debajo!</span>
                            </ng-template>
                        </div>

                    </button>

                </div>
            </div>

        </div>
        <hr>
        <div class="info" #target>
            <h3 class="blue">Dentro de Termas de Concordia</h3>
            <p>
                Te ofrecemos 4 alternativas dentro del Parque termal de Concordia. Los Bungalows son de 3 ambientes,
                completamente equipados para que vivas tus vacaciones en el confort ideal. El paquete incluye Servicio
                de mucamas, ropa de cama y toallas, y el beneficio de estar dentro del predio termal con sus 11 piscinas
                termales con distintas temperaturas según la estación del año.
            </p>


        </div>

        <div class="flex-big">
            <ng-container *ngFor="let grupo of grupos;let gIdx=index">
                <app-categoria *ngIf="gIdx!=5" [grupoIdx]="gIdx" [busqueda]="busqueda" (ir)="scrollToFormulario()">
                </app-categoria>

            </ng-container>
        </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14118.334262953851!2d-58.00462247174566!3d-31.293982368240506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4b120d4098729a18!2sBungalows+Para%C3%ADso!5e0!3m2!1sen!2sar!4v1544042906421"
            width="100%" height="300" frameborder="0" style="border:0" allowfullscreen>
        </iframe>

        <div class="spacer"></div>
        <hr>

        <div class="info">
            <h3 class="green">Nuestra Opción al margen del Río Uruguay</h3>
            <p>
                Te ofrecemos un duplex en la costa del río Uruguay con vista al río y al país vecino, completamente
                equipadas con parrilla, garage techado privado, una pequeña pileta, finísimo amoblamiento, televisores
                LEDs y red de wifi. ¡Viví unas vacaciones en familia rodeado de naturaleza y paz!
            </p>
        </div>
        <div class="flex-big">
            <app-categoria [grupoIdx]="grupos.length-1" [busqueda]="busqueda" (ir)="scrollToFormulario()">
            </app-categoria>
        </div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.0363096561946!2d-57.99086488990456!3d-31.385562330223596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x56df8c79ac51e235!2sBungalows+Para%C3%ADso+R%C3%ADo!5e0!3m2!1sen!2sar!4v1544042373089"
            width="100%" height="300" frameborder="0" style="border:0" allowfullscreen>
        </iframe>

    </div>
    <!-- <div class="previaje">
        <img src="assets/previaje.jpg" alt="">
        <div class="flotante">
            <button mat-raised-button color="primary" (click)="openModalPreViaje()"> ¿Qué es Pre-Viaje?</button>
        </div>
    </div> -->
    <div class="footer share">
        <button mat-raised-button color="primary">
            <a [href]="'https://api.whatsapp.com/send?text=Visita%20nuestro%20sitio%20'+ruta" target="_blank">Enviar por
                WhatsApp</a>
        </button>
    </div>
    <div class="footer">
        <div class="info">
            <h4>Contacto</h4>

            <p>
                <mat-icon>share</mat-icon>
                <span>&commat;paraisoconcordia</span>
            </p>
            <p>
                <mat-icon>email</mat-icon>
                <span>contacto&commat;paraisoconcordia.com.ar</span>
            </p>
            <p>
                <mat-icon>call</mat-icon>
                <span>345 402 1930</span>
            </p>

        </div>
        <div class="info">
            <h4>Más Información</h4>

            <p (click)="openModalFAQ()" class="faq">
                <mat-icon>help_outline</mat-icon>
                <span>Preguntas Frecuentes</span>
            </p>
            <p (click)="openModalPreViaje()" class="faq">
                <mat-icon>loyalty</mat-icon>
                <span>Somos Prestadores de Pre-Viaje</span>
            </p>
            <!-- <p>
                <mat-icon>call</mat-icon>
                <span>345 402 1930</span>
            </p> -->

        </div>

    </div>
    <div class="lastline">
        <p>
            <img src="assets/logo_negro_transparente.png" alt="">
            <span>Bungalows Paraíso 2021© <br>Concordia. ER</span>
        </p>
    </div>

    <div class="fixed">
        <a class="wts"
            href="https://wa.me/5493454021930/?text=Hola%20Bungalows%20Paraíso!%20Consulta%20desde%20la%20web:%20"
            target="blank">
            <img src="assets/logowapp.png" alt="">
        </a>
        <div class="faq">
            <button mat-mini-fab color="link" (click)="openModalFAQ()">
                <mat-icon>help_outline</mat-icon>
            </button>
        </div>
        
    </div>


</div>