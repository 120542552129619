<div class="container">
    <div class="flex">
        <img class="logo" src="assets/logo_chico.png" alt="">
    </div>
    <div class="body">
        <h2>Reservar</h2>
        <p class="info">
            Usted está por reservar Paraíso {{grupo.nombre}} para {{+busqueda.mayores+ +busqueda.menores}} pasajeros, del {{busqueda.checkin | date:"dd/MM/yy"}} al {{busqueda.checkout | date:"dd/MM/yy"}} {{busqueda.late=='si'?'a las 18 h':'a las 10 h'}}. Esta unidad se encuentra {{grupo.nombre=="Río"?'al margen del río Uruguay en la ciudad de Concordia':'dentro del Parque Termal de Concordia'}}. Usted dispondrá de 48 h hábiles para abonar ${{(senia) | number:".0-0"}} en concepto de seña para así confirmar la Reserva.
        </p>

        <div class="alert tarifasAlert">
            <h4>Tarifa Aceptada</h4>
            <div class="tarifas">
                <span class="col1">Seña requerida:</span>
                <span>$ {{(senia) | number:".0-0"}}</span>
            </div>
            <div class="tarifas">
                <span class="col1">Saldo*:</span>
                <span>${{(grupo.tarifa.despues-senia) | number:".0-0"}}</span>
            </div>
            <div class="tarifas">
                <span class="col1">Total:</span>
                <span>${{grupo.tarifa.despues | number:".0-0"}}</span>
            </div>
            <ul>
                <li>Incluye estadía completa</li>
                <li *ngIf="grupo.nombre=='Río'">Incluye Wifi gratuito</li>
                <li *ngIf="grupo.nombre!=='Río'">Incluye Ingresos Termales para toda la estadía</li>
                <li *ngIf="grupo.nombre!=='Río'">Incluye Servicio de mucamas</li>
            </ul>
            
            <span class="info-saldo">*: el saldo se abona durante el Ingreso</span>
        </div>

        <!-- <div class="alert">
            <h5>REQUISITOS DE LA PROVINCIA PARA INGRESO TURÍSTICO 2020 - 2021</h5>
            <ul >
              <li>Reserva previa de Alojamiento</li>
              <li>Certificado Verano: www.argentina.gob.ar/verano</li>
              <li>Foto de DNI</li>
              <li>NO es necesario hisopado</li>
            </ul>

        </div> -->

        <div class="alert" *ngIf="grupo.nombre!=='Río';else HorarioRIO">
            <h4>Horario de Ingreso</h4>
            <p>Para cumplir los protocolos provinciales y la adecuada desinfección y ventilación de los ambientes, el horario de ingreso será a las 15 h. De encontrarse la unidad vacía la noche anterior, el ingreso podrá adelantarse a las 14 hs.</p>
        </div>
        <ng-template #HorarioRIO>
            <div class="alert">
                <h4>Horario de Ingreso</h4>
                <p>El horario de ingreso para Paraíso Río es 14hs.</p>
                <h4>Usted está enviando una Solicitud.</h4>
                <p>Dado que esta unidad tiene múltiples canales de venta, procesaremos esta solicitud de reserva de forma manual y le enviaremos un correo lo más pronto posible con la respuesta.</p>
            </div>
        </ng-template>
    
        <h4>Rormulario de Reserva</h4>
        <p>Complete el siguiente formulario para finalizar el proceso de {{grupo.nombre!=='Río'?'SOLICITUD DE PRE-RESERVA':'PRE-RESERVA'}}</p>
        <div class="form">
            <mat-form-field appearance="outline">
                <mat-label>Apellido y Nombre</mat-label>
                <input matInput type="text" required [(ngModel)]="data.nombre">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>DNI</mat-label>
                <input matInput type="number" required [(ngModel)]="data.dni">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Correo electrónico</mat-label>
                <input matInput type="text" required [(ngModel)]="data.mail">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Repeti tu Correo</mat-label>
                <input matInput type="text" required [(ngModel)]="mail2">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Teléfono</mat-label>
                <input matInput type="text" required [(ngModel)]="data.tel">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Mensaje (opcinal)</mat-label>
                <textarea matInput type="text" [(ngModel)]="data.mensaje">
                </textarea>
            </mat-form-field>
            <div>
                <mat-checkbox class="checkbox" [(ngModel)]="terminos"></mat-checkbox>
                <span (click)="openDialog()" class="vinculo">Acepto Términos y Condiciones</span>
            </div>
            <br>
            <div *ngIf="busqueda.mascotas>0">
                <mat-checkbox class="checkbox" [(ngModel)]="ddjjMascotas"></mat-checkbox>
                <span class="vinculo">Declaro que acepto perder la reserva sin derecho a reclamo alguno si la mascota supera los 9 kg al momento del ingreso. </span>
            </div>
    
            <div class="flex">
                <button mat-button routerLink="">Volver</button>
                <button mat-raised-button color="warn"
                 (click)="confirmar()" [disabled]="!data.nombre||!data.mail||!data.dni||data.mail!=mail2||!data.tel||!terminos||searching || !ddjjMascotas">
                    <div class="center2">
                        <mat-icon *ngIf="!searching;else spinn">check</mat-icon>
                        <ng-template #spinn>
                            <mat-spinner diameter=20></mat-spinner>
                        </ng-template>
                        <span>Confirmar</span>
                    </div> 
                </button>
            </div>
        </div>
    
    </div>

</div>