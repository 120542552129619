import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiCallService } from '../api-call.service';
import { BusquedaI, GrupoI } from '../interfaces';

declare var fbq:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[]=[];

  constructor(private apiCall: ApiCallService, public dialog: MatDialog, private route: ActivatedRoute, private router:Router,private location: Location) { 
    
  }
  MsDay = 24 * 60 * 60 * 1000;
  reserva: boolean;
  dateIn: Date;
  dateOut: Date;
  searching:boolean;
  @ViewChild('target', { static: false }) targetRef: ElementRef;
  @ViewChild('formulario', { static: false }) formularioRef: ElementRef;
  @ViewChild('checkinTarget', { static: false }) checkinTarget: ElementRef;

  busqueda: BusquedaI; 
  grupos: GrupoI[];

  min1: Date;
  ruta:string;

  ngOnInit(): void {
    console.log('inicializando');
    // this.router.events.subscribe(params => { console.log(params); });
    
    let sub1=this.apiCall.busqueda$.subscribe(b=>{
      this.busqueda=b;      
      this.dateIn = new Date(new Date(b.checkin).getTime()+this.MsDay/24*3);
      this.dateOut = new Date(new Date(b.checkout).getTime()+this.MsDay/24*3);
      
    });

    let sub2=this.apiCall.grupos$.subscribe(gs=>{
      this.grupos=gs;      
    });
    let sub3=this.apiCall.searching$.subscribe(b=>{
      this.searching=b;    
      
      if(this.searching===false){        
        if(this.targetRef){
          this.targetRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
      }  
    });
    this.subscriptions.push(sub1,sub2,sub3);   

    this.min1 = new Date();
    let params = this.route.snapshot.queryParams;
    this.ruta=window.location.href.replace(/&/gi,'%26');
    // console.log({ruta:this.ruta});
    
    if(params.modal){
      if(params.modal == 'previaje') this.openModalPreViaje();
      if(params.modal == 'FAQ') this.openModalFAQ();
  
    }

    if (params.checkin && params.checkout) {
      let busqueda = {
        checkin: params.checkin,
        checkout: params.checkout,
        late: params.late ? params.late : 'no',
        mayores: params.mayores ? +params.mayores : 2,
        menores: params.menores ? +params.menores : 0,
        mascotas: params.mascotas ? +params.mascotas : 0
      };
      this.apiCall.nextBusqueda(busqueda);
      this.calcularTarifa();

      let d1=(params.checkin as string).split('-');
      let d2=(params.checkout as string).split('-');
      this.dateIn = new Date(+d1[0],+d1[1]-1,+d1[2]);
      this.dateOut = new Date(+d2[0],+d2[1]-1,+d2[2]);
    } 
    if(environment.production)fbq('track', 'PageView');

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s=>s.unsubscribe());
  }

  compareCheckout(ch1:string,ch2:string){
    // console.log({ch1,ch2});
    
    return ch1===ch2;
  }

  ajustarTarifa(){
    this.apiCall.tableDigest();
  }
  

  changeDateOut() {
    this.dateOut = new Date(this.dateIn.getTime() + this.MsDay);

  }
  openModalPreViaje(): void {
    const dialogRef = this.dialog.open(ModalPreViaje, {
      width: '350px',
      data: {
        titulo: 'Pre-Viaje'
      }
    });    
    // let params = {ok:'y',modal:'previaje'};
    // this.router.navigate([],{
    //   relativeTo: this.route,
    //   queryParams:params,
    //   queryParamsHandling:'merge'
    // });
    // dialogRef.afterClosed().subscribe(() => {
    //   // let params = {...this.route.snapshot.queryParams,modal:'nope'};      
    //   let params = {modal:'nope'};      
    //   // delete params.modal;
    //   this.router.navigate([], {
    //     relativeTo: this.route,
    //     queryParams:params,
    //     queryParamsHandling:'merge'
    //   });
    //   // this.router.navigate([],{relativeTo: this.route,queryParams:params});
    // });
  }

  openModalFAQ(): void {
    const dialogRef = this.dialog.open(ModalPreViaje, {
      width: '350px',
      data: {
        titulo: 'Preguntas Frecuentes'
      }
    });
    // let params = {...this.route.snapshot.queryParams,modal:'FAQ'};
    // this.router.navigate([],{relativeTo: this.route,queryParams:params});
    // dialogRef.afterClosed().subscribe(() => {
    //   let params = {...this.route.snapshot.queryParams};      
    //   delete params.modal;
    //   const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: params}).toString();      
    //   this.location.go(url)
    //   // this.router.navigate([],{relativeTo: this.route,queryParams:params});

    // });    
  }

  openModalError(error: string) {
    const dialogRef = this.dialog.open(ModalPreViaje, {
      // width: '250px',
      data: {
        titulo: 'Lo sentimos...',
        body: error
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  

  calcularTarifa() {
    // console.log('corriendo calcularTarifa()');
    if(environment.production)fbq('track', 'Search',{search_string:'busquedaFecha'});
    let busqueda=this.busqueda;
    busqueda.checkin = this.dateIn.toJSON().slice(0, 10);
    busqueda.checkout = this.dateOut.toJSON().slice(0, 10);
    this.apiCall.nextBusqueda(busqueda);
    this.router.navigate([],{relativeTo: this.route,queryParams:busqueda});
    setTimeout(() => {
      this.ruta=window.location.href.replace(/&/gi,'%26');
    }, 0);   

    this.apiCall.calcularBoth()

  }

  inputChange() {
    let grupos = [...this.grupos];
    grupos.forEach(g => {
      g.dispo = 0;
      g.unidadesDispo=[];
      g.display = 'Presione Buscar Tarifa y Disponibilidad';
      g.tarifa = null
    });
    this.apiCall.nextGrupos(grupos);
  }


  
  
  scrollToFormulario(){
    console.log('estoy corriendo FS scrollToFormulario()');
    
    this.formularioRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.checkinTarget.nativeElement.focus({
      preventScroll: true
    });
  }

 


}

@Component({
  selector: 'modal-previaje',
  templateUrl: 'modal-previaje.html',
  styles: [`
  .mat-dialog-content{
    padding: 0px !important;
}`]
})
export class ModalPreViaje {
  constructor(
    public dialogRef: MatDialogRef<ModalPreViaje>,
    @Inject(MAT_DIALOG_DATA) public data: { titulo: string, body?: string }) { }

  previajeSlides = [
    'assets/previaje/1.jpg',
    'assets/previaje/2.jpg',
    'assets/previaje/3.jpg',
    'assets/previaje/4.jpg',
    'assets/previaje/5.jpg',
    'assets/previaje/6.jpg',
  ]

  onNoClick(): void {
    this.dialogRef.close();
  }

}
