<h1 mat-dialog-title>{{data.titulo}}</h1>
<div mat-dialog-content>
    <carousel *ngIf="data.titulo=='Pre-Viaje'" [slides]="previajeSlides" [previaje]="true"></carousel>
    
    <p *ngIf="data.body" [innerHTML]="data.body" style="padding: 20px;"></p>

    <div *ngIf="data.titulo == 'Preguntas Frecuentes'" style="padding: 20px; padding-top: 0px;">
      <p><b>Tarifas</b> Las tarifas se calculan a partir de la cantidad de noches, de pasajeros, y la temporada. Podes calcular la tarifa en menos de 1 minuto completando el formulario de 6 pasos. Es fácil y rápido, y no dependes de los horarios de nuestra oficina para encontrar lo que buscas.</p>

      <p><b>Entradas Termales.</b> Tenemos unidades tanto dentro como fuera de las Termas de Concordia. Solo las Unidades dentro de las termas incluyen el costo de las entradas para toda la estadía. Paraíso RIO no incluye las entradas termales.</p>
      
      <p><b>¿Qué incluyen las unidades dentro de TERMAS?</b>
      Las tarifas que se muestran en unidades dentro de termas incluyen Alojamiento para toda la estadía, entradas termales, Servicio de Mucama, ropa de cama y toallas para uso exclusivo dentro de la unidad, wifi gratuito.
      </p>

      <p><b>¿Qué incluyen las unidades frente al RIO?</b>
      Los duplex Paraíso RIO (fuera del predio termal) incluyen Alojamiento para toda la estadía, ropa de cama y toallas, wifi gratuito. No incluyen Entradas Termales, ni Servicio de Mucama. En estadías extensas se coordinará la entrega de juegos limpios de sábana y toallas.
      </p>      

      <p><b>Reservas</b> Este sitio es una central de reservas y muestra información fehaciente de la disponibilidad y tarifas, permitiendote realizar una pre-reserva. Al reservar, se te enviará un correo con los datos para abonar la seña.</p>

      <p><b>Seña</b> La seña representa el 50% de la estadía, o una noche, lo que sea mayor. Esta confirma la pre-reserva y bloquea la unidad hasta su visita, generando así la Reserva.</p>

      <p><b>Política de Cancelación</b> Se puede modificar las fechas de ingreso y/o egreso sin cargo alguno hasta 7 o más días previos al ingreso. Se perderá la seña ante cancelaciones con menos de 7 días faltantes al ingreso. El dinero de la Seña no se devuelve, se reserva para una futura visita. </p>

      <p><b>Cancelación por DNUs</b> Debido a la Pandemia, de haber un DNU o un cierre provincial que impida la salida, el tránsito, o el ingreso de una/ a una provincia,  se podrá conservar la seña para una futura visita. El dinero de la Seña no se devuelve, se reserva para una futura visita. </p>

      <p><b>Horarios de Ingreso y Egreso a la unidad</b> Nuestras unidades dentro de termas, tienen un horario de ingreso a partir de las 15 h (hasta la madrugada), y un horario de salida a las 10 h. En cambio, nuestros duplex Paraíso RIO, tienen un horario de ingreso a partir de las 14 h hasta las 20 h, y un horario de salida entre las 8 h y las 10 h.</p>

      <p><b>Salida Tardía o Late Check Out.</b> Si usted desea extender el horario de salida hasta las 18 h, debe extender su horario con late check out. Esta opción  que se muestra en el formulario de consultas, tiene un costo extra dado que la unidad se bloqueda durante esa ese día impidiendo el ingreso de otro pasajero.</p>

      <p><b>Garage y Parrilla</b> Todas nuestras unidades tienen garage techado y parrilla techada. El costo está incluido en la Tarifa.</p>

      <p>
        <b>Ubicación de las Camas</b> Todas nuestras unidades tienen 2 dormitorios. Dependiendo de la categoría, la unidad puede tener más camas. Relax ,Standard y Platinum 418 tienen cama 2 plazas en dormitorio princial y 2 singles en secundario, 5ta y 6ta en living-comedor. 
        <br><br>Unidades Premium tienen cama 2 plazas + cama single en dormitorio principal, y 3 camas singles en secundario, con 7ma y 8va plaza en living-comedor.
        <br><br>Platinum Accesible tiene cama 2 plazas en dormitorio principal, y 3 camas singles en secundario, con 6ta y 7ma plaza living-comedor.
        <br><br> Por último, fuera de termas, Paraíso RIO 1 tiene en Planta Alta cama 2 plazas en dormitorio principal, y 3 camas singles en secundario. Paraíso RIO 2 tiene cama 2 plazas en dormitorio principal, y 2 camas singles en secundario, con 5ta y 6ta plaza en cama marinera en Planta Baja.
      </p>

      
      

      
    </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>