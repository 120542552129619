import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Event, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'paraiso';

  constructor(router: Router, viewportScroller: ViewportScroller){
    router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe(e => {
      
      if (!router.url.includes('checkin')) {
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
