<div class="col">
    <img class="logo" src="assets/logo_chico.png" alt="">
    <img class="flyer" src="assets/cuadricula.jpg" alt="">
</div>
<div class="info">
    <h3>Felicitaciones!</h3>
    <p>
        Hemos recibido correctamente tu soliciutd de Reserva.<br>
        Pronto recibiras un correo con toda la información detallada de como abonar la seña.
    </p>
    <p>Equipo Bungalows Paraíso</p>

    <button mat-raised-button routerLink=""> Volver </button>
</div>
  