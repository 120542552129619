<div class="flex header">
  <h4 mat-dialog-title>{{grupo.nombre}}</h4>
  <span *ngIf="grupo.tarifa?.despues;else spinn"> ${{grupo.tarifa.despues|number:".0-0"}}</span>
  <ng-template #spinn>
      <mat-spinner diameter=30 *ngIf="searching"></mat-spinner>
  </ng-template>
</div>
<div class="sub-header">
  <h5 class="margin-big">{{grupo.tarifa?.despues?(noches+' noches '):''}}{{grupo.nombre=='Río'?'Frente al río':'En Termas'}} para
  </h5>    
  <button mat-mini-fab color="warn" [matMenuTriggerFor]="menu">
    <b>{{busqueda.mayores+busqueda.menores}}</b><mat-icon>perm_identity</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div class="container-menu" (click)="$event.stopPropagation()">
      <div class="fila-menu">
        <div class="left">
          <span>Adultos</span>
          <span class="detalle">
            7 o más años
          </span>
        </div>
        <div class="right">
          <button mat-mini-fab color="warn" (click)="sumarPax(0,false,$event)"
          [disabled]="busquedaCopy.mayores==1">-</button>{{busquedaCopy.mayores}}<button mat-mini-fab color="warn" (click)="sumarPax(0,true,$event)" [disabled]="(busquedaCopy.mayores+busquedaCopy.menores)==grupo.capacidad">+</button>
        </div>
      </div>

      <div class="fila-menu">
        <div class="left">
          <span>Menores</span>
          <span class="detalle">
            3 a 6 años
          </span>
        </div>
        <div class="right">
          <button mat-mini-fab color="warn" (click)="sumarPax(1,false,$event)" [disabled]="busquedaCopy.menores==0">-</button>{{busquedaCopy.menores}}<button mat-mini-fab color="warn" (click)="sumarPax(1,true,$event)" [disabled]="(busquedaCopy.mayores+busquedaCopy.menores)==grupo.capacidad">+</button>
        </div>
      </div>

      <div class="fila-menu">
        <div class="left">
          <span>Mascotas</span>
          <span class="detalle">
            Razas pequeñas hasta 9 Kg
          </span>
        </div>
        <div class="right">
          <button mat-mini-fab color="warn" (click)="sumarPax(2,false,$event)" [disabled]="busquedaCopy.mascotas==0">-</button>{{busquedaCopy.mascotas}}
          <button mat-mini-fab color="warn" (click)="sumarPax(2,true,$event)" [disabled]="busquedaCopy.mascotas>=grupo.mascotas">+</button>
        </div>
      </div>
      
      <button mat-menu-item class="aceptarBtn" (click)="ajustarTarifa();" >Aceptar</button>
      <span class="info-menu">- Esta Unidad tiene una Capacidad Máxima para {{grupo.capacidad}} húespedes.</span><br>
      <span class="info-menu">- Bebes de 2 años o menos no cuentan.</span><br>
      <span class="info-menu">- {{grupo.mascotas>0? 'Esta unidad acepta '+grupo.mascotas+' mascota'+(grupo.mascotas>1?'s':''):'Esta unidad no acepta Mascotas'}}</span>

    </div>

  </mat-menu>

</div>
<div mat-dialog-content> 

  <div class="container">
    <carousel [slides]="grupo.imgs" [mostrar_accesibilidad]="grupo.nombre=='Platinum accesible'"></carousel>
    
  </div>
  <div class="flex margin-big">
    <div class="col1">
      <mat-icon *ngFor="let icon of grupo.icons" [matTooltip]="icon.tip">{{icon.icon}}</mat-icon>
    </div>
    <div class="col2">
      <ng-container *ngIf="grupo.tarifa;else sinTarifa">
        <span class="price" >${{grupo.tarifa.despues|number:".0-0"}}</span>
        <br><small>Estadía completa</small>

      </ng-container>
      
      <ng-template #sinTarifa>
        <span class="link" (click)="scrollFormulario()">{{grupo.display}}</span>
      </ng-template>

    </div>  
  </div>

  <div class="cal-info-container">
    <div class="calendario">

      <div class="title">
        <h3 >Resumen</h3>
      </div>
      <p class="info margin-big">
        <b>Capacidad Máxima: {{grupo.capacidad}}</b><br>
        <b>Capacidad de Mascotas: {{grupo.mascotas}}</b><br>
        <b>Unidades Disponibles: </b><br>
        <span *ngFor="let unidad of grupo.unidadesDispo; let i=index"> N° {{unidad}} {{i<(grupo.unidadesDispo.length-1)?'• ':''}}</span>
        <br>
      </p>
      
      <hr>
      <div class="title">
        <h3>Disponibilidad</h3>
      </div>
        
      <ng-container *ngIf="calResp;else Loading">
  
        <p>Elija una Unidad:</p>
        <div class="unidades flex-cal">          
          <button 
            mat-mini-fab  
            [matTooltip]="uniIdx==idx?'Unidad Selecionada':''"
            class="uni" *ngFor="let uni of calResp.data.unidades;let idx=index" (click)="armarCalendario(idx)" [ngClass]="{'selected':idx==uniIdx}">{{uni}}
          </button>
        </div>
        <div class="togle">
          <mat-slide-toggle
          color="warn"
          [checked]="busqueda.late=='si'"
          [(ngModel)]="lateCheckout"
          [disabled]="searching"
          (change)="changeBusqueda();">
        Salgo a las 18 h 
      </mat-slide-toggle>
        </div>
        <div class="anio" *ngFor="let mes of calNow; let ii=index">
          <div class="mes-header">
            <mat-icon (click)="restarMes()" *ngIf="ii==0" [ngClass]="{'icon-disabled':disableLeftArrow}">arrow_back_ios</mat-icon>
            <h4>{{mesesFull[ii==0?m1:m2]}}</h4>
            <mat-icon (click)="sumarMes()" *ngIf="ii==1">arrow_forward_ios</mat-icon>
          </div>

          <div class="flex">
            <div class="col" *ngFor="let dia of dias">{{dia}}</div>
          </div>
          <div class="table">
            <div class="row flex" *ngFor="let row of mes">
              <div class="col" *ngFor="let col of row" [class]="col.clase" (click)="clickCal(ii,col)">{{col.dia}}
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <ng-template #Loading>
        <mat-spinner></mat-spinner>
      </ng-template>      
  
    </div>

    <div class="info-unidad">
      <h3 class="info margin-big">¿Qué ofrece Paraíso {{grupo.nombre}}?</h3>
      <p class="info margin-big info-unidad" *ngFor="let detalle of grupo.info">
        {{detalle}}
      </p>
      <div *ngIf="grupo.nombre=='Platinum accesible'" class="info discapacidad margin-big">
        <div>
          <img src="/assets/perro_asistencia.png" alt="">
          <p>
            <b>Ley Nacional 26.858</b>
          </p>
        </div>
        <img src="/assets/accesibilidad_fondo_azul.jpg" alt="">
      </div>
     <!-- {{grupo  | json}} -->
      <ng-container *ngIf="grupo.grupo ==5">
        <h3 class="info margin-big">Planos RIO 1:</h3>
        <img class="margin-big fotos-rio" src="assets\rio\plano1.png" alt="">

        <h3 class="info margin-big">Planos RIO 2:</h3>
        <img class="margin-big fotos-rio" src="assets\rio\plano2.png" alt="">

      </ng-container>



    </div>
  </div>  

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="fbkInitiate()" cdkFocusInitial [disabled]="grupo.dispo==0 || !grupo.tarifa">Reservar</button>
</div>