import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiCallService } from '../api-call.service';
import { BusquedaI, CheckoutI, GrupoI, ReservaI } from '../interfaces';

declare var fbq: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor(private router: Router, private apiCall: ApiCallService, public dialog: MatDialog) {
    let state = router.getCurrentNavigation().extras.state as { grupo: GrupoI, busqueda: BusquedaI };
    if (!state) {
      router.navigate(['']);
      return;
    }
    this.grupo = state.grupo;
    this.busqueda = state.busqueda;
    let noches=Math.floor((new Date(this.busqueda.checkout).getTime()-new Date(this.busqueda.checkin).getTime())/24/60/60/1000);
    let costo=+this.grupo.tarifa.despues - +this.grupo.tarifa.entradas;
    this.senia=Math.max(costo*.5,costo/noches);
  }

  senia:number;
  searching=false;
  busqueda: BusquedaI;
  grupo: GrupoI;
  data: CheckoutI = {
    nombre: '',
    dni: null,
    mail: '',
    tel: '',
    mensaje: ''
  };
  mail2: string = null;
  terminos = false;
  ddjjMascotas:boolean;

  ngOnInit(): void {
    this.ddjjMascotas= !(this.busqueda.mascotas>0);
  }

  confirmar() {
    this.searching=true;
    let unidadName:string;
    let unidad=this.grupo.unidadesDispo[0];
    //2 AMBIENTES,3 AMBIENTES, 2 Amb c/ Pileta, 3 Amb c/ Pileta,PREMIUM,PLATINUM,CAB 2 AMB,CAB 3 AMB,CAB 2 AMB C/PILETA,HOSTERÍA ESTÁNDAR,HOSTERÍA CONFORT
    if(unidad<10){
      unidadName='PAPRAISO RIO';
    }else if(unidad==610 || unidad==612){
      unidadName='PARAISO RELAX';
    }else if(unidad==412 || unidad==414){
      unidadName='PARAISO STANDARD';
    }else if(unidad>504 && unidad<512){
      unidadName='PARAISO PREMIUM';
    }else if(unidad==416 || unidad==418){
      unidadName='PARAISO PLATINUM';
    }

    let reserva: ReservaI = {
      funcion: "reservar",
      unidad,
      categoria: this.grupo.nombre,
      tarifa: this.grupo.tarifa.despues,
      entradas: this.grupo.tarifa.entradas,
      apellido: this.data.nombre,      
      late:this.busqueda.late, 
      nombre:this.data.nombre,
      dni:this.data.dni,
      tel:this.data.tel,
      mail:this.data.mail,

      //necesita FichasMenuCS de teras
      dir:'sin datos',
      msg:this.data.mensaje,//antes mensaje
      isHotel:false,
      checkin:this.string2days(this.busqueda.checkin), //days
      checkout:this.string2days(this.busqueda.checkout), //days
      paxAdultos:this.busqueda.mayores,
      paxMenores:this.busqueda.menores,
      paxMascotas:this.busqueda.mascotas,
      unidadName,
      tabla:this.grupo.tabla.toString(), //tabla: (Global.outputTable).toString(),
      cat:this.grupo.cat,
      desayuno:0,
      plazo:48,
      senia:this.senia
    };
    if(environment.production)fbq('track', 'Purchase', {currency: "ARS", value: reserva.tarifa});

    this.apiCall.Reservar(reserva).subscribe(res => {
      console.log({ res });      
      if (res.status) {       
        if(res.status==2){
          alert(res.msg);
        }
        this.router.navigate(['congrats']);
      } else {
        this.searching=false;
        alert(res.msg);
      }

    });

  }

  string2days(dateStr:string) {
    var dateObj = new Date(dateStr);
    return Math.floor(dateObj.getTime() / 1000 / 60 / 60 / 24);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TerminosDialog, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result){
        this.terminos=true;
      }
    });
  }


  // meses=['enero' ,'febrero', 'marzo', 'abril','mayo',' junio','julio','agosto','setiembre','octubre','noviembre','diciembre'];

}

@Component({
  selector: 'dialog-overview-example-dialog',
  // templateUrl: 'dialog-overview-example-dialog.html',
  styles:['button{color:black !important;}'],
  template: `
  <h4 mat-dialog-title>Términos y Condiciones - Bungalows Paríso</h4>
  <div mat-dialog-content>
    <p>La presente solicitud es una <b>Pre-Reserva</b>, que será tomada por la administración y confirmada posteriormente por correo electrónico.</p>  
    
    <p>En el correo se mostrará los medios para poder abonar la <b>Seña</b>. A partir de la emisión de dicho correo se establecerá el plazo para abonar la seña (48h hábiles).</p>

    <p>La <b>Seña</b> representa 1 noche o el 50% del total de la estadìa, lo que sea mayor.</p>

    <p>Una vez realizada la <b>Seña</b>, solo se aceptarán cambios de fecha con 7 días de anticipación a la fecha del ingreso. No se aceptan cambios con una anticipación menor a 7 días del ingreso. Sin excepción. </p>

    <p>En las estadías reprogramadas mantiene sin variación el porcentaje abonado en concepto de <b>Seña</b>, no así el importe restante que completan el total de la estadía. Estas diferencias podrán surgir por cambio de tarifas, o por cambio de temporada (temporada alta, media, baja).</p>

    <p>Si su unidad acepta una o más mascotas, la mismas no deberán superar los 9 Kg al momento del ingreso. Si esto no se cumple, podrá ser <b>motivo de cancelación de la reserva</b>, perdiendo así el importe depositado/transferido en nuestras cuentas. </p>
  
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cerrar</button>
    <button mat-raised-button color="warn" (click)="acepto()" cdkFocusInitial>Acepto</button>
  </div>
  `
})
export class TerminosDialog {

  constructor(
    public dialogRef: MatDialogRef<TerminosDialog>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  acepto(){
    this.dialogRef.close(true);
  }

}
