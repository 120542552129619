<div class="carousel" [ngClass]="{'previaje':previaje}">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img *ngIf="i === currentSlide" [src]="slide" class="slide" />
  </ng-container>
  <div class="directrices"  *ngIf="mostrar_accesibilidad">
    <img src="/assets/accesibilidad_b.png" alt="">
  </div>  

  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
  
</div>