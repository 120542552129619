import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { ApiCallService } from 'src/app/api-call.service';
import { ArmarCalendarioRespI, BusquedaI, DiaCalI, GrupoI } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
declare var fbq:any;

@Component({
  selector: 'app-ver-mas',
  templateUrl: './ver-mas.component.html',
  styleUrls: ['./ver-mas.component.scss']
})
export class VerMasComponent implements OnInit {
  dias=['do','lu','ma','mi','ju','vi','sa'];
  meses=['Ene','Feb','Mar','Abr','May','jun','Jul','Ago','Set','Oct','Nov','Dic'];
  mesesFull=['Enero','Febrero','Marzo','Abril','Mayo','junio','Julio','Agosto','Setiembre','Octubre','Noviembre','Diciembre'];
  m1:number;
  m2:number;
  mesIdx:number;

  disableLeftArrow:boolean=false;
  calResp:ArmarCalendarioRespI;
  calNow:DiaCalI[][][];//mes semana dia
  uniIdx:number;
  subscriptions:Subscription[]=[];
  busqueda:BusquedaI;
  grupo: GrupoI;
  searching: boolean;
  noches: number;
  busquedaCopy:BusquedaI;
  lateCheckout:boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;


  constructor(
    public dialogRef: MatDialogRef<VerMasComponent>,
    @Inject(MAT_DIALOG_DATA) public grupoIdx:number,
    private apiCallS:ApiCallService
    ) { 
      
    }
    

  ngOnInit(): void {
    let sub1=this.apiCallS.busqueda$.subscribe(b=>{
      this.busqueda=b;
      this.busquedaCopy={...b};
      this.noches= Math.floor((new Date(b.checkout).getTime()-new Date(b.checkin).getTime())/24/60/60/1000);
      this.lateCheckout = this.busqueda.late=='si';
      // console.log({lateCheckout:this.lateCheckout});
      
    });
    let sub2=this.apiCallS.grupos$.subscribe(gs=>{
      console.log({gs});
      this.grupo=gs[this.grupoIdx];
      if(this.calResp===undefined){
        this.getCalendario();
      }else if(this.grupo.display!=='Definir una estadía'){//no romper el calendario cuando se blanquea, sino cuando viene una respuesta luego de TableDigest(). 
        this.armarCalendario();
      }

    });
    let sub3=this.apiCallS.searching$.subscribe(b=>this.searching=b);
    let sub4=this.apiCallS.mesesIdx$.subscribe(m=>{
      this.mesIdx=m;
      this.setMeses();
    });
    

    this.subscriptions.push(sub1,sub2,sub3,sub4);
  }

  ajustarTarifa(){
    this.trigger.closeMenu();
    if(this.grupo.display == 'Definir una estadía'){//no se ha eligido una fecha
      return;
    }
    this.apiCallS.nextBusqueda(this.busquedaCopy);
    this.apiCallS.tableDigest();
  }

  changeBusqueda(){
    this.busqueda.late=this.lateCheckout?'si':'no';
    console.log({buesqueda:this.busqueda});    
    this.apiCallS.nextBusqueda(this.busqueda);
    this.apiCallS.calcularBoth();
  }

  sumarPax(tipo:number,doSuma:boolean,event:Event){
    event.stopPropagation();
    if(tipo==0){
      this.busquedaCopy.mayores+= (doSuma?1:-1);
    } else if(tipo==1){
      this.busquedaCopy.menores+= (doSuma?1:-1);      
    } else if(tipo==2){
      this.busquedaCopy.mascotas+= (doSuma?1:-1);      
    }
  }

  setMeses(){
    
    let hoy= new Date();
    this.m1= new Date(hoy.getFullYear(),this.mesIdx,1).getMonth();
    this.m2= new Date(hoy.getFullYear(),this.mesIdx+1,1).getMonth();    
    // console.log({mesIdx:this.mesIdx,hoy,m1:this.m1,m2:this.m2});
    
  }

  getCalendario(){
    this.calResp=null;
    if(this.grupo.grupo!=5){
      this.apiCallS.getCalendario(this.grupo.grupo).subscribe(res=>{
        this.calResp=res;
        this.armarCalendario();
      });
    }else{
      this.apiCallS.getCalendarioRIO().subscribe(res=>{
        this.calResp=res;
        this.armarCalendario();
      });
    }
  }
  sumarMes(){ 
    if(environment.production)fbq('track', 'Schedule');    
    this.apiCallS.nextMesesIdx(this.mesIdx+1);    
    this.getCalendario();
    // this.setMeses();
    
  }
  restarMes(){ 
    let mes=new Date().getMonth();
    const newmes=this.mesIdx-1;
    if(new Date(2020,newmes+1,1).getMonth()==mes){
      return;
    }
    if(environment.production)fbq('track', 'Schedule');
    this.apiCallS.nextMesesIdx(newmes);
    this.disableLeftArrow=(new Date(2020,newmes,1).getMonth()==mes);
            
    this.getCalendario();
    // this.setMeses();

  }

  armarCalendario(uniIdx?:number){
    let resp=this.calResp;
    if(resp?.status==1){
      if(uniIdx===undefined){
        if(this.grupo.unidadesDispo.length){
          uniIdx=resp.data.unidades.findIndex(u=>u==this.grupo.unidadesDispo[0]);
        }else{
          uniIdx=0;
        }
      };
      this.uniIdx=uniIdx;
      let cal=resp.data.calendario[uniIdx];
      let calNow:DiaCalI[][][]=[];//mes, semana, dia
      let dia1=resp.data.dia1;
      let old_i=0;
      let ocupadaAnterior=true;
      let checkin:DiaCalI;
      let checkout:DiaCalI;
      let monthIn:number;
      let monthOut:number;
      for(let ii=0;ii<2;ii++){//mes 0 y 1
        let dia1Semana=new Date((dia1)*24*60*60*1000).getUTCDay();//0 es domingo        
        calNow.push([[]]);
        for(let i=0;i<dia1Semana;i++){
          let d:DiaCalI={
            dia:null,
            estado:'ocupada',
            clase:'tachada'
          }
          calNow[ii][calNow[ii].length-1].push(d);
        } 
        let dia,tempDia;  
        let date:Date;   
        for(let i=0;i<(cal.length);i++){
          tempDia=dia;
          date=new Date((dia1+i)*24*60*60*1000);
          dia=date.getUTCDate();
          if(tempDia!==undefined&&tempDia>dia){
            dia1+=i;
            old_i=i;
            break;
          }
          let estado:DiaCalI["estado"];
          if(cal[i+old_i]==0){//ocupada
            if(ocupadaAnterior || this.lateCheckout){
              estado='ocupada';  
            }else {
              estado='solo-salida';
            }
          }else{
            estado='libre';
          }
          let diaC:DiaCalI={
            dia,
            estado,
            clase:null
          }
          calNow[ii][calNow[ii].length-1].push(diaC); 
          ocupadaAnterior= cal[i+old_i]==0;   
          if((i+dia1Semana+1)%7==0){//si se completó una semana.
            calNow[ii].push([]);          
          }
          if(date.getTime()== new Date(this.busqueda.checkin).getTime()){
            checkin=diaC;
            monthIn=ii;
          }else if(date.getTime() == new Date(this.busqueda.checkout).getTime()){
            checkout=diaC;
            monthOut=ii;
          }
        }
      }
      this.calNow=calNow;
      this.blankCal();
      let unidad = resp.data.unidades[uniIdx];
      if(this.grupo.tarifa && this.grupo.unidadesDispo.findIndex(u=>u==unidad)>-1){
        this.clickN=1;
        this.clickCal(monthIn,checkin,true);
        this.clickN=2;
        this.clickCal(monthOut,checkout,true);
      }
    }
  }

  blankCal(){
    let cal=this.calNow;
    for(let m=0;m<2;m++){
      for(let s=0;s<this.calNow[m].length;s++){
        for(let d=0;d<this.calNow[m][s].length;d++){
          let n=cal[m][s][d];
          if(n.estado=='ocupada'){
            n.clase='tachada';
          }else{
            n.clase='canHover';
          }
        }
      }
    }
    this.calNow=cal;
  }

  clickN=1;
  clickCal(ii:number,diaC:DiaCalI,avoidTarifar?:boolean){//ii es 0 o 1 (primer o segundo mes del calendario)
    if(!diaC)return;
    if(diaC.dia===null||diaC.estado=='ocupada'||diaC.clase=='tachada')return;
    if(this.clickN==1){
      this.clickN=2;
      let cal=this.calNow;
      let status:'before'|'after'|'tachar'='before';
      for(let m=0;m<2;m++){
        for(let s=0;s<this.calNow[m].length;s++){
          for(let d=0;d<this.calNow[m][s].length;d++){
            let dia=cal[m][s][d];
            if(dia.dia===null)continue;
            if(dia.dia==diaC.dia && ii==m){//dia de ingreso
              status='after';
              dia.clase='in';
            }else if(status=='before'){
              dia.clase='tachada';
            }else if(status=='after'){
              if(dia.estado=='libre'||dia.estado=='solo-salida'){
                dia.clase='canHover'
              }else if(dia.estado=='ocupada'){
                dia.clase='tachada';
                status='tachar';
              }
            }else if(status=='tachar'){
              dia.clase='tachada';
            }
          }  
        }
      }

    } else{
      this.clickN=1;
      if( diaC.clase=='in'){
        this.blankCal();
      } else {
        let checkin:string;
        let checkout:string;
        let anio= new Date().getFullYear();
        let cal=this.calNow;
        let status:'sincerar'|'bloquear'='sincerar';
        for(let m=0;m<2;m++){
          for(let s=0;s<this.calNow[m].length;s++){
            for(let d=0;d<this.calNow[m][s].length;d++){
              let dia=cal[m][s][d];
              if(dia.dia===null)continue;
              if(dia.clase=='in'){//dia de ingreso
                status='bloquear';
                checkin=new Date(anio,this.mesIdx+m,dia.dia).toJSON().slice(0,10);
                
              }else if(dia.dia==diaC.dia&&m==ii){//dia de salida
                status='sincerar';
                dia.clase='out';
                checkout=new Date(anio,this.mesIdx+m,dia.dia).toJSON().slice(0,10);

              }else if(status=='sincerar'){
                dia.clase=dia.estado=='ocupada'?'tachada':'canHover';

              }else if(status=='bloquear'){
                dia.clase='filled';
              }
            }
          }
        }
        // console.log({checkin,checkout});
        if(avoidTarifar===undefined||avoidTarifar===false){
          this.busqueda.checkin=checkin;
          this.busqueda.checkout=checkout;
          this.apiCallS.nextBusqueda(this.busqueda);
          this.apiCallS.calcularBoth();
        }
        
      }
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  scrollFormulario(){
    this.dialogRef.close('scroll');
  }

  fbkInitiate(){ 
    if(environment.production)fbq('track', 'InitiateCheckout');
  }

}
