import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArmarCalendarioI, ArmarCalendarioRespI, BusquedaI, FxsOutput, GrupoI, MailVentaI, ReservaI, ResponseBusqI, ResponseRioI } from './interfaces';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  MsDay = 24 * 60 * 60 * 1000;
  hoy = new Date();
  manana = new Date(this.hoy.getTime() + this.MsDay * 1);
  private busquedaBS = new BehaviorSubject<BusquedaI>({
    checkin: this.hoy.toJSON().slice(0, 10),
    checkout: this.manana.toJSON().slice(0, 10),
    late: 'no',
    mayores: 2,
    menores: 0,
    mascotas: 0
  });
  private gruposBS = new BehaviorSubject<GrupoI[]>([]);
  private searchingBS = new BehaviorSubject<boolean>(null);

  busqueda$ = this.busquedaBS.asObservable();
  grupos$ = this.gruposBS.asObservable();
  searching$ = this.searchingBS.asObservable();

  private meseIdxBS: BehaviorSubject<number> = new BehaviorSubject((new Date()).getMonth());
  mesesIdx$ = this.meseIdxBS.asObservable();

  subscriptions: Subscription[] = [];
  response: { termas: ResponseBusqI; rio: ResponseRioI; };
  
  apiUrl = 'https://paraisoconcordia.com.ar/api'; //Api on server

  
  // production FichasMenuCS => termas/GAS/...
  fichasMenuCS='https://script.google.com/macros/s/AKfycbzdM-hPYBlhL_Sk6YwEGvtCO6gS35PNJkMbv4UHy_9La-kCGn3In7gdFqwVT-IyAHyg/exec';
  // TEST
  // fichasMenuCS='https://script.google.com/macros/s/AKfycby4T62GVay7F-Cu-a7Dpoe6V6vfzca8Gv458-Y3nBVRPeNIWpRfOVwdrmah7JQvmdIM5g/exec';

  // Production DisponibilidadCS => termas/GAS/...
  disponibilidadCS = 'https://script.google.com/macros/s/AKfycbweOSxBvt3bY5IkHt03KsAAP8usYZmNDgYC2aQaYY-GTON-2EY/exec';
  // Test
  // disponibilidadCS = 'https://script.google.com/macros/s/AKfycbxHz1_l17rCQU7a-7qMQ-B0WjyPtzztZlJKenqoHzITTxA7VfkTNV_Gdkdv7TVfcsHA/exec';

  // Production ParaisoCS => paraiso/GAS/...
  paraisoCSpath = 'https://script.google.com/macros/s/AKfycbz0lzIm_JgR5WsXbzHBu-Jm7RKcNRKwY1gLMyptjrzl4CMlD-axbE8viTgchtJB4jeq/exec';
  // TEST
  // paraisoCSpath='https://script.google.com/macros/s/AKfycbxo1cohSNx6LHp3odhK8VaWB9mpWBP9jvjL7xKF3e9irOzxLNp7n42hqOryb-bhLwV5/exec';


  constructor(private http: HttpClient) {
    this.setCleanGrupo();
  }

  nextMesesIdx(a: number) { this.meseIdxBS.next(a); }

  nextBusqueda(b: BusquedaI) {
    this.busquedaBS.next(b);
    let ckin = new Date(b.checkin);
    let hoy = new Date();
    this.nextMesesIdx(ckin.getUTCMonth() + 12 * (ckin.getUTCFullYear() - hoy.getUTCFullYear()))
  }
  nextGrupos(gs: GrupoI[]) {
    this.gruposBS.next(gs);
  }
  nextSearching(b: boolean) {
    this.searchingBS.next(b);
  }

  calcularBoth() {
    this.nextSearching(true);
    this.setCleanGrupo();
    let sub = combineLatest(
      this.fetchDisponibilidadCS(this.busquedaBS.value),
      this.calcularParaisoRio(this.busquedaBS.value)
    ).pipe(filter(([t, r]) => !!t && !!r))
      .subscribe(([termas, rio]) => {
        this.nextSearching(false)
        if (rio.status !== 1) {
          alert('Error en el servidor. Intente nuevamente!');
          console.log({rio,termas});
          
        } else if (termas.Status != 1) {
          alert(termas.mensaje);
          // this.openModalError(termas.mensaje);
          return;

        } else {

          this.response = { termas, rio: (rio.data as ResponseRioI) };
          this.tableDigest();
        }

      });
    this.subscriptions.push(sub);
  }

  tableDigest() {
    console.log({rio:this.response.rio});
    
    this.setCleanGrupo();
    if (!this.response) return;
    let termas = this.response.termas;
    let rio = this.response.rio;
    // console.log({rio});
    
    let grupos = [...this.gruposBS.value];
    grupos.forEach(g => g.dispo = 0);
    let busqueda = this.busquedaBS.value;

    let disponible = [...termas.disponible, rio.disponible];
    for (let cat = 0; cat < disponible.length; cat++) {
      let newcat = cat;
      let entradas = +termas.EntradasArr[0] * (busqueda.mayores + busqueda.menores) + termas.EntradasArr[0] * busqueda.mascotas;
      let rioTarifa:number[];
      let nochesMinRio:number;
      if (cat == disponible.length - 1) {//si es parapiso RIO
        newcat = 4; // [2 amb, 3 amb, 2 amb c pileta, 3 amb c pileta, premium, platinum ]        
        entradas = 0;
        rioTarifa = rio.tarifas.map(v=>((v <=0 || v>3 )?1:v)); // menor que 0 % o mayor al 200% que categoria Premium
        nochesMinRio = Math.max(...rio.nochesMinimas.map(v=>((v <=0 || v>15 )?2:v)) )
      }
      // console.log({rioTarifa});
      
      let sub = this.Tarifar(termas.Tarifa, termas.BigTable, newcat, termas.BASE, termas.MODULO, busqueda.late == 'si',rioTarifa);
      let tarifaDD = sub.tarifaAD - sub.desc1 - sub.desc2 - sub.desc3;
      var paqueteNow = tarifaDD + entradas;
      var paqueteBef = sub.tarifaAD + entradas;
      var descAloj = Math.abs((tarifaDD / sub.tarifaAD - 1) * 100);
      let noches = sub.table.length - (busqueda.late == 'si'?1:0);

      grupos.forEach(g => g.display = 'Sin Disponibilidad. Consultar otras fechas');
      for (let aloj = 0; aloj < disponible[cat].length; aloj++) {
        let unidad = disponible[cat][aloj];
        let indiceRow = rio.indice.find(row => row[1] == unidad);
        let gIdx = indiceRow[12];
        grupos[gIdx].dispo++;
        grupos[gIdx].unidadesDispo.push(unidad);
        grupos[gIdx].tabla = sub.table;
        grupos[gIdx].cat = cat;
        let busqueda = this.busquedaBS.value;
        if (indiceRow[4] < (+busqueda.mayores + +busqueda.menores)) {
          grupos[gIdx].display = 'Supera Capacidad';
          continue;
        } else if (indiceRow[5] < (+busqueda.mascotas)) {
          let msg = 'No Acepta Mascotas';
          if(indiceRow[5]>0){
            msg = "Esta Unidad acepta hasta " + indiceRow[5] + 'mascota'+ (indiceRow[5]>1?'s':'');
          }
          grupos[gIdx].display = msg;
          continue;
        } else if (tarifaDD == 0) {
          grupos[gIdx].display = 'No presentamos Tarifas para esta fecha';
          continue;
          
        } else if( noches < nochesMinRio ) {// si es otra cosa que rio => nochesMinRio es undefined=> false y no entra
          grupos[gIdx].display = 'Requisito de '+nochesMinRio+' noches para esta fecha';
          
        } else {
          grupos[gIdx].display = '';
          grupos[gIdx].tarifa = {
            entradas,
            antes: paqueteBef,
            despues: paqueteNow,
            descuento: descAloj,
          }

        }
      }
    }
    // console.log({grupos});

    this.nextGrupos(grupos);
  }

  Tarifar(tarifaAD: number[][], descTable: number[], cat: number, BASE: number[][], MODULO: number[][], late: boolean, rioTarifa?:number[]) {
    let busqueda = this.busquedaBS.value;
    var tarifa = 0;
    var desc1 = 0;
    var desc2 = 0;
    var desc3 = 0;
    var temp1, temp2, temp3, t;
    var table = [];
    let pax = +(busqueda.mayores) + 0.5 * +(busqueda.menores) + (rioTarifa? +busqueda.mascotas : 0)* 0.5;
    for (var n = 0; n < tarifaAD.length; n++) {
      // t = tarifaAD[n][cat];
      let f = (late && n == (tarifaAD.length - 1)) ? 0.5 : 1;
      // console.log({f});

      t = (BASE[n][cat] + MODULO[n][cat] * Math.max(0, (pax - 2))) * f * (rioTarifa? rioTarifa[n]:1);
      temp1 = t * descTable[n][0];
      temp2 = (t - temp1) * descTable[n][1];
      temp3 = (t - temp1 - temp2) * descTable[n][2];
      desc1 += temp1;
      desc2 += temp2;
      desc3 += temp3;
      tarifa += t;
      table[n] = [BASE[n][cat], MODULO[n][cat], pax, descTable[n][0], descTable[n][1], descTable[n][2]];
    }
    // console.log({table});


    var out = {
      tarifaAD: tarifa,
      desc1,
      desc2,
      desc3,
      table
    };

    return out;
  }

  setCleanGrupo() {
    let grupos: GrupoI[] = [
      {
        grupo: 610,
        nombre: 'Relax',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 2,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'pets', tip: 'Acepta Mascotas' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'cleaning_services', tip: 'Servicio de Mucamas' }, { icon: 'local_parking', tip: 'Garage cubierto' }],
        capacidad: 6,
        info: [
          " Nuestros BUNGALOWS son de tres ambientes, son  muy cómodos, completamente equipados, con ropa de cama, sabanas y toallas, vajilla completa. Tienen 2 dormitorios (uno con cama matrimonial y otro con 2 camas singles), living comedor, cocina, parrilla y garaje cubiertos. Una 5ta y 6ta plaza se pueden ubicar en el living comedor (especial para niños)",
          "Tienen Aire Acondicionado, calefactores, horno microondas, secadores de pelo, cocina c/ horno ,  SERVICIO DE MUCAMA, 2 televisor LCD  32” y  Direc TV. Todos los  BUNGALOWS PARAISO  poseen agua termal. Esta categoría se encuentra a unos 200 metros de las piletas termales frente a un pinar, lo cual le brinda un clima de absoluta relajación."
        ],

        imgs: [
          'assets/relax/img1.jpg',
          'assets/relax/img2.jpg',
          'assets/relax/img3.jpg',
          'assets/relax/img4.jpg',
          'assets/relax/img5.jpg',
          'assets/relax/img6.jpg',
        ]
      },
      {
        grupo: 412,
        nombre: 'Standard',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 2,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'pets', tip: 'Acepta Mascotas' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'cleaning_services', tip: 'Servicio de Mucamas' }, { icon: 'local_parking', tip: 'Garage cubierto' }],
        capacidad: 6,
        info: [
          "Nuestros BUNGALOWS son de tres ambientes, son  muy cómodos, completamente equipados, con ropa de cama, sabanas y toallas, vajilla completa . Están compuestos por 2 dormitorios ,uno con cama matrimonial, y otro con dos camas singles, living comedor, baño cocina, parrilla , galería , y garaje cubierto.- Estos presentan a su vez una 5ta y 6ta plaza ubicada en el living comedor, especial para familias con varios niños.",
          "Tienen Aire Acondicionado, calefactores, estufas, horno microondas,  ventiladores de techo, secadores de pelo, cocina c/ horno ,  SERVICIO DE MUCAMA,  televisor LCD y  Direc TV. Estos bungalows presentan parrilla en el frente. Todos los  BUNGALOWS PARAISO  poseen agua termal. Esta categoría se encuentra a 150 metros de las piletas termales."
        ],
        imgs: [
          'assets/standard/img1.jpg',
          'assets/standard/img2.jpeg',
          'assets/standard/img3.jpg',
          'assets/standard/img4.jpg',
          'assets/standard/img5.jpg',
        ]
      },
      {
        grupo: 505,
        nombre: 'Premium',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 0,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'cleaning_services', tip: 'Servicio de Mucamas' }, { icon: 'local_parking', tip: 'Garage cubierto' }, { icon: 'hot_tub', tip: 'Jacuzzi con agua termal' }],
        capacidad: 8,
        info: ["Únicos dentro del parque homologados por la Dirección de Turismo de la Provincia Entre Ríos, con categoría  TRES ESTRELLAS , BUNGALOWS  con MINI PISCINA SPA en el jardín para seis personas, CAJA DE SEGURIDAD , TELEVISOR LCD , SOMIER , DOS AIRES  ACONDICIONADOS, CALEFACCIÓN CENTRAL PEISA, TOSTADORAS , etc. El máximo confort dentro del parque termal para  disfrutar de las bondades de nuestra agua termal, en total privacidad.",
          "Esta Unidad se encuentra frente a las piletas, a aproximadamente 80m de las mismas."

        ],
        imgs: [
          'assets/premium/img1.jpg',
          'assets/premium/img10.jpg',
          'assets/premium/img2.jpg',
          'assets/premium/img3.jpg',
          'assets/premium/img4.jpg',
          'assets/premium/img5.jpg',
          'assets/premium/img7.jpg',
          'assets/premium/img8.jpg',
          'assets/premium/img9.jpg',
          'assets/premium/img10.jpg',
        ]
      },
      {
        grupo: 416,
        nombre: 'Platinum accesible',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 0,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'cleaning_services', tip: 'Servicio de Mucamas' }, { icon: 'local_parking', tip: 'Garage cubierto' }, { icon: 'hot_tub', tip: 'Jacuzzi con agua termal' }, { icon: 'king_bed', tip: 'Sommier tamaño King' }, { icon: 'accessible', tip: 'Cumple con Directrices de Accesibilidad MINTUR' }],
        capacidad: 7,
        info: [
          "Única unidad en todo el parque termal Accesible. Esta unidad cumple con las Directrices de Accesibilidad otorgadas por el Ministerio de Turismo de la Nación. Por lo tanto, la unidad está perfectamente acondicionada para personas con movilidad reducida.",
          "El máximo equipamiento y confort:  Tres aires acondicionados, tres televisores LED, finísimo mobiliario, decoración de alta gama, sauna,  ducha escocesa,  mini piscina spa, mas todos el equipamiento de nuestros  PARAISO PREMIUM.",
          "La unidad se encuentra a unos 140 metros de las piscinas termales.",
          "Aceptamos perros de asistencia según Ley Nacional 26.858, mediante presentación de Certificado correspondiente."

        ],
        imgs: [
          'assets/platinum416/img1.jpg',
          'assets/platinum416/img2.jpg',
          'assets/platinum416/img3.jpg',
          'assets/platinum416/img4.jpg',
          'assets/platinum416/img5.jpg',
          'assets/platinum416/img7.jpg',
          'assets/platinum416/img9.jpg',
          'assets/platinum416/img10.jpg',
          'assets/platinum416/img11.jpeg',
          'assets/platinum416/img12.jpeg',
          'assets/platinum416/img13.jpeg',
        ]
      },
      {
        grupo: 418,
        nombre: 'Platinum',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 0,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'cleaning_services', tip: 'Servicio de Mucamas' }, { icon: 'local_parking', tip: 'Garage cubierto' }, { icon: 'hot_tub', tip: 'Jacuzzi con agua termal' }, { icon: 'king_bed', tip: 'Sommier tamaño King' }, { icon: 'spa', tip: 'Sauna seco' }],
        capacidad: 6,
        info: [
          "Paraíso Platinum presenta el máximo equipamiento y confort:  Tres aires acondicionados, tres televisores LED, finísimo mobiliario, decoración de alta gama, sauna,  ducha escocesa,  mini piscina spa, mas todos el equipamiento de la categría Premium.",
          "La unidad se encuentra a unos 140 metros de las piscinas termales."
        ],
        imgs: [
          'assets/platinum418/img1.jpg',
          'assets/platinum418/img2.jpg',
          'assets/platinum418/img3.jpg',
          'assets/platinum418/img4.jpg',
          'assets/platinum418/img5.jpg',
          'assets/platinum418/img7.jpg',
          'assets/platinum418/img8.jpg',
          'assets/platinum418/img9.jpg',
          'assets/platinum418/img10.jpg',
          'assets/platinum418/img11.jpg',
        ]
      },
      {
        grupo: 5,
        nombre: 'Río',
        display: 'Definir una estadía',
        dispo: 0,
        unidadesDispo: [],
        mascotas: 1,
        icons: [{ icon: 'wifi', tip: 'Wifi gratuito' },{ icon: 'tv', tip: 'TV LED' }, { icon: 'pets', tip: 'Acepta 1 Mascota' }, { icon: 'ac_unit', tip: 'Aire Acondicionado' }, { icon: 'outdoor_grill', tip: 'parrilla cubierta' }, { icon: 'local_parking', tip: 'Garage cubierto' }, { icon: 'hot_tub', tip: 'Piscina tipo Jacuzzi (agua fría)' }, { icon: 'king_bed', tip: 'Sommier tamaño King' }],
        capacidad: 5,
        info: [
          "Paraíso Río se encuentra a las orillas del Río Uruguay, con una hermosa vista al río y a nuestro país hermano. El predio consta de 2 unidades tipo dúplex, en un terreno de 600 metros cuadrados. Las unidades presentan ambientes amplios y luminosos en dos plantas. Están totalmente equipadas y finamente decoradas.",
          "La distribución de las unidades, permiten que en planta baja la cocina-comedor sea cómoda para una zona de estar con TV vía cable, además de contar con toilette. En la planta alta se ubican los dos dormitorios y un baño completo. El dormitorio principal cuenta con una cama matrimonial con TV y AC. El dormitorio secundario cuenta con 2/3 camas singles y AC. En el patio se encuentra un hall con parrilla semi-cubierta, y una piscina descubierta pequeña tipo jacuzzi de material de agua fria (de 2.00 x 1.50 metros) independiente a cada unidad. ",
          "El predio dispone de garaje cubierto y Wifi. ",
          "Especificaciones: las unidades cuentan con pequeñas diferencias arquitectónicas (ver plano inferior) lo que modifica la capacidad de pasajeros que permiten cada una.",
          "RÍO 1: segundo dormitorio de 3 camas singles.",
          "RÍO 2: segundo dormitorio de 2 camas singles. Esta unidad presenta la posibilidad de ubicar una 5ª y 6ª plaza en planta baja en cama marinera del living-comedor.",
          "Por último, PARAÍSO RÍO se encuentra a 9 min (4.0 km) del centro de Concordia, a 23 min (13.6 km) de las Termas de Concordia, y a 32 min (21.9 km) del Lago y Represa Salto Grande."
        ],
        imgs: [
          'assets/rio/img1.jpeg',
          'assets/rio/img2.jpg',
          'assets/rio/img3.jpg',
          'assets/rio/img4.jpg',
          'assets/rio/img5.jpg',
          'assets/rio/img6.jpeg',
          'assets/rio/img7.jpeg',
          'assets/rio/img8.jpeg',
          'assets/rio/img9.jpg',
          'assets/rio/img10.jpg',
          'assets/rio/img12.jpg',
          'assets/rio/img13.jpg',
          'assets/rio/img14.jpg',
        ]
      },
    ];
    this.nextGrupos(grupos);
  }

  
  fetchDisponibilidadCS(e: BusquedaI) {    
    var url = this.disponibilidadCS +
      '?checkin=' + e.checkin +
      '&checkout=' + e.checkout +
      '&islate=' + e.late +
      '&paxAdultos=' + e.mayores +
      '&paxMenores=' + e.menores +
      '&paxMascotas=' + e.mascotas +
      '&esHotel=' + false +
      '&MimeType=JSON';
    // console.log({ url });

    return this.http.get(url).pipe(
      map((data: ResponseBusqI) => {
        // console.log({ data });
        if (data.Status == 1) {
          var unidades = [412, 414, 416, 418, 505, 507, 509, 511];
          var dispo = data.disponible;
          var bp_dispo = [];
          var _loop_1 = (cat: number) => {
            bp_dispo.push([]);
            dispo[cat].forEach(uni => {
              if (unidades.includes(uni)) bp_dispo[cat].push(uni);
            });
          };
          for (var cat = 0; cat < dispo.length; cat++) {
            _loop_1(cat);
          }
          //paso de categoria Bw 3 amb a Cab 3 amb.
          if (dispo[1].includes(610)) {
            bp_dispo[7].push(610);
          }
          if (dispo[1].includes(612)) {
            bp_dispo[7].push(612);
          }
          data.disponible = bp_dispo;
        }
        return data;


      }
      )
    )


  }

  
  calcularParaisoRio(e: BusquedaI) {
    return this.http.post<FxsOutput>(this.paraisoCSpath, JSON.stringify(e));
  }

  Reservar(e: ReservaI) {
    console.log({e})
    return this.http.post<FxsOutput>(this.paraisoCSpath, JSON.stringify(e));
  }

  getCalendarioRIO() {
    let data: ArmarCalendarioI = {
      grupo: 5,
      isHotel: false,
      mes: this.meseIdxBS.value
    };
    const postRequest = {
      funcion: 'getCalendarioSS',
      data
    };
    // console.log({postRequest});

    return this.http.post<ArmarCalendarioRespI>(this.paraisoCSpath, JSON.stringify(postRequest))
  }

  public sendVentaMail(form: MailVentaI) {
    return this.http.post(`${this.apiUrl}/mailVenta.php`, JSON.stringify(form));
  }
  
  getCalendario(grupo: number) {   
    let data: ArmarCalendarioI = {
      grupo,
      isHotel: false,
      mes: this.meseIdxBS.value
    };
    const postRequest = {
      funcion: 'armarCalendarioSS',
      data
    };
    return this.http.post<ArmarCalendarioRespI>(this.fichasMenuCS, JSON.stringify(postRequest));
  }

}
