import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiCallService } from 'src/app/api-call.service';
import { BusquedaI, GrupoI } from 'src/app/interfaces';
import { VerMasComponent } from '../ver-mas/ver-mas.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit, OnDestroy {
  @Input() grupoIdx:number;
  @Input() busqueda:BusquedaI;
  @Output() ir = new EventEmitter();
  grupo: GrupoI;
  grupos:GrupoI[];
  subscriptions:Subscription[]=[];
  searching:boolean;
  
  constructor(public dialog: MatDialog, private router:Router,private apiCallS:ApiCallService) { 
  }

  ngOnInit(): void {
      let sub1= this.apiCallS.grupos$.subscribe(gs=>{
        this.grupo=gs[this.grupoIdx];
        this.grupos=gs;
      });
      let sub2=this.apiCallS.searching$.subscribe(b=>this.searching=b);
      this.subscriptions=[sub1,sub2];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s=>s.unsubscribe());
  }

  reservar:boolean;

  openDialog(event?:Event): void {
    if(event){
      event.stopPropagation();
    }
    const dialogRef = this.dialog.open(VerMasComponent, {
      width: '100%',
      // height:'95vh',
      data: this.grupoIdx
    });
    
    dialogRef.afterClosed().subscribe((result:boolean|string) => {
      console.log('The dialog was closed',result);
      if(typeof result == 'boolean'){
        if(result){
          this.reservar = result;
          this.router.navigate(["checkout"],{state:{grupo:this.grupo,busqueda:this.busqueda}});
        }

      }else if( typeof result == 'string'){
        if(result=='scroll')this.emitScroll();
      }
    });
  }

  emitScroll(){
    this.ir.emit();
  }

}
