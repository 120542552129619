<mat-card>
    <div class="bg" [ngStyle]="{background: 'url(../'+grupo.imgs[0]+')'}" (click)="openDialog()">
        <div class="btn">
            <button mat-raised-button color="warn" (click)="openDialog($event)">Ver más</button>
        </div>
        <div class="directrices">
            <img *ngIf="grupo.nombre=='Platinum accesible'" src="/assets/accesibilidad_b.png" alt="">
        </div>
    </div>
    <mat-card-content>
        <h4 class="title-h4">Paraíso {{grupo.nombre}}</h4>
        <div class="flex titles">
            <span class="col-left">
                <div class="flex">
                    <div class="icons">
                        <mat-icon *ngFor="let icon of grupo.icons" [matTooltip]="icon.tip">{{icon.icon}}</mat-icon>
                    </div>
                    
                </div>

            </span>

            
            <span *ngIf="grupo.tarifa;else spinn">
                <p *ngIf="grupo.tarifa.descuento>0">
                    <span class="antes">$ {{+grupo.tarifa.antes  | number:".0-0"}}</span>   
                    <span class="off"> {{+grupo.tarifa.descuento | number:".0-0"}}% OFF</span>
                </p>
                 <p class="despues">                     
                     $ {{+grupo.tarifa.despues | number:".0-0"}}
                     <br>
                     <span>Estadía completa</span>
                 </p>
            </span>
            <ng-template #spinn>        
                <ng-container *ngIf="searching;else sinTarifa">
                    <p >
                        <mat-spinner diameter=30 *ngIf="searching"></mat-spinner>
                    </p>
                    <p>Calculando <br>tarifa ...</p>
                </ng-container>        
            </ng-template>

            <ng-template #sinTarifa> 
                <p (click)="emitScroll()" class="link">{{grupo.display}}</p>
            </ng-template>
        </div>

    </mat-card-content>
</mat-card>


